import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Link, Input, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Котеджні Мрії
			</title>
			<meta name={"description"} content={"Творімо домівки та краєвиди, де кожен куточок розповідає вашу історію"} />
			<meta property={"og:title"} content={"Котеджні Мрії"} />
			<meta property={"og:description"} content={"Творімо домівки та краєвиди, де кожен куточок розповідає вашу історію"} />
			<meta property={"og:image"} content={"https://kyivkaleidoscope.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://kyivkaleidoscope.com/img/9.jpg) center/cover"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Text margin="0px 0px 0px 0px" font="--headline1" sm-font="--headline3">
						Контакти
					</Text>
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="580px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Адреса
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Україна, 03124, місто Київ, ВУЛИЦЯ МИКОЛИ ВАСИЛЕНКА, будинок 7 А
ТОВ "ФОРМ ФАКТОР"
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:goncarovaoksana673@gmail.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									goncarovaoksana673@gmail.com
								</Link>
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Box>
						<Box
							padding="56px 48px"
							margin="0 0 0 auto"
							md-max-width="100%"
							background="rgba(11, 11, 4, 0.52)"
							max-width="480px"
						>
							<Text as="h3" font="--headline3" margin="0 0 20px 0">
								Звʼяжіться з нами
							</Text>
							<Formspree endpoint="xeqpgrlv">
								<Box gap="16px" display="flex" flex-direction="row" flex-wrap="wrap">
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text margin="0 0 4px 0" font="--base">
												Імʼя
											</Text>
											<Input max-width="400px" width="100%" name="name" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Email
											</Text>
											<Input max-width="400px" width="100%" type="email" name="email" />
										</Box>
									</Box>
									<Box padding="8px 8px 8px 8px" width="100%">
										<Box display="flex" flex-direction="column">
											<Text font="--base" margin="0 0 4px 0">
												Повідомлення
											</Text>
											<Input width="100%" name="message" as="textarea" rows="4" />
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column">
											<Components.QuarklycommunityKitCheckbox>
												<Override slot="Text" color="--light">
													Я ознайомився з{" "}
													<Link href="/privacy" hover-text-decoration="underline" color="--light">
														Правилами та Умовами
													</Link>
													{" "}і даю згоду на обробку моїх даних.
												</Override>
												<Override
													slot="Input"
													min-width="20px"
													min-height="20px"
													text-align="left"
													display="block"
													margin="0px 5px 0px 0px"
												/>
											</Components.QuarklycommunityKitCheckbox>
										</Box>
									</Box>
									<Box width="100%" padding="8px 8px 8px 8px">
										<Box display="flex" flex-direction="column" align-items="flex-start">
											<Button background="--color-primary" text-decoration-line="initial">
												Відправити
											</Button>
										</Box>
									</Box>
								</Box>
							</Formspree>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});